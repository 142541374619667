import { PIX_ISSUER_ID } from '../data/constants';
import { AmountDetail } from '../types/amount.types';
import { VerticalType } from '../types/enums';
import { Issuer } from '../types/paymentMethod.types';
import { issuerHelper } from './issuer.helper';

const getAmountFormatted = (amount: number | null | undefined) =>
  amount?.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 });

const getCurrency = (currency: string) => {
  const currencyFormatted: string | undefined = currency === 'USD' ? 'U$S' : '$';
  return currencyFormatted;
};

const getCurrencyText = (currency: string | null) => {
  const currencyFormatted: string | undefined = currency === 'USD' ? 'Dólares' : 'Pesos Uruguayos';
  return currencyFormatted;
};

const getAmountFormattedWithCurrency = (currency: string, amount: string | number | null) => {
  const currencyFormatted: string | undefined = getCurrency(currency);
  const total: string | undefined = `${currencyFormatted} ${getAmountFormatted(Number(amount))}`;
  return total;
};

const getDebtWithCommission = (
  debtAmount: number | null,
  amountFee: number | null,
  percentageFee?: number | null,
) => {
  const debtWithCommission: number | null = debtAmount
    ? debtAmount * (1 + (percentageFee || 0) / 100) + (amountFee || 0)
    : debtAmount;
  return debtWithCommission;
};

const getDebtWithTip = (debtAmount: number | null, tipPercentage: number) => {
  const debtWithTip: number | null = debtAmount && debtAmount * (1 + tipPercentage / 100);
  return debtWithTip;
};

const getCommissionText = (
  percentageFee: number | null | undefined,
  amountFee: number | null | undefined,
  currency: string | undefined,
  gatewayIssuerId?: string,
  verticalType?: VerticalType,
) => {
  const amountFeeFormatted = amountFee ? getAmountFormatted(amountFee) : '';
  const percentageFeeFormatted = percentageFee ? getAmountFormatted(percentageFee) : '';
  const percentage = percentageFeeFormatted ? '%' : '';
  const plus = percentageFeeFormatted && amountFee ? ' + ' : '';
  const currencySymbol = amountFee && currency ? getCurrency(currency) : '';
  const information =
    issuerHelper.isPixIssuer(gatewayIssuerId) && (amountFee || percentageFee) ? ' (Fee) + FX ' : '';
  const disabledIssuer = gatewayIssuerId === PIX_ISSUER_ID && currency === 'UYU';

  const commissionText = () => {
    if (!disabledIssuer) {
      if (percentageFeeFormatted || amountFeeFormatted) {
        const percentagePart = percentageFeeFormatted || '';
        const amountPart = `${currencySymbol} ${amountFeeFormatted}`;
        return `${percentagePart}${percentage}${plus}${amountPart}${information}`;
      }

      if (!percentageFeeFormatted && !amountFeeFormatted) {
        return '0%';
      }
    }

    return '-';
  };

  return commissionText();
};

const getFixedCommission = (currency: string, issuer: Issuer | null | undefined) => {
  switch (currency) {
    case 'UYU':
      return issuer?.fixedCommissionUyu;
    case 'USD':
      return issuer?.fixedCommissionUsd;
    default:
      return undefined;
  }
};

const getTotalAmount = (currency: string, amountDetail: AmountDetail | null) => {
  switch (currency) {
    case 'UYU':
      return amountDetail?.UYU?.totalAmount;
    case 'USD':
      return amountDetail?.USD?.totalAmount;
    default:
      return undefined;
  }
};

const getTipAmount = (currency: string, amountDetail: AmountDetail | null) => {
  switch (currency) {
    case 'UYU':
      return amountDetail?.UYU?.tipAmount || 0;
    case 'USD':
      return amountDetail?.USD?.tipAmount || 0;
    default:
      return undefined;
  }
};

const getRestaurantTotalFormatted = (currency: string, amountDetail: AmountDetail | null) => {
  const totalAmount = amountHelper.getTotalAmount(currency, amountDetail);
  const tipAmount = amountHelper.getTipAmount(currency, amountDetail) || 0;
  const totalAmountWithTip = totalAmount && totalAmount + tipAmount;
  const totalAmountWithTipFormatted = amountHelper.getAmountFormatted(totalAmountWithTip);
  const currencyFormatted = amountHelper.getCurrency(currency);
  const totalFormattedWithCurrency = currencyFormatted + ' ' + totalAmountWithTipFormatted;
  return totalFormattedWithCurrency;
};

const getPaymentMethodStyle = (
  gatewayIssuerId: string,
  selectedIssuerId: string | undefined,
  currency: string | undefined,
  verticalType?: VerticalType,
) => {
  const selectedClass = gatewayIssuerId === selectedIssuerId ? 'selected' : '';
  const disabledClass = issuerHelper.isPixIssuer(gatewayIssuerId) && currency === 'UYU' ? 'disabled' : '';
  return disabledClass || selectedClass;
};

const getCurrencyCode = (currency: string) => {
  switch (currency.toUpperCase()) {
    case 'PESO':
    case 'PESOS':
      return 'UYU';
    case 'DOLAR':
    case 'DOLARES':
      return 'USD';
    default:
      return undefined;
  }
};

const getVatReturnAmount = (currency: string, amountDetail: AmountDetail | null) => {
  switch (currency) {
    case 'UYU':
      return getAmountFormatted(amountDetail?.UYU?.vatReturnAmount);
    case 'USD':
      return getAmountFormatted(amountDetail?.USD?.vatReturnAmount);
    default:
      return undefined;
  }
};

export const amountHelper = {
  getAmountFormatted,
  getCurrencyText,
  getCurrency,
  getAmountFormattedWithCurrency,
  getTotalAmount,
  getTipAmount,
  getDebtWithCommission,
  getDebtWithTip,
  getCommissionText,
  getRestaurantTotalFormatted,
  getPaymentMethodStyle,
  getCurrencyCode,
  getVatReturnAmount,
  getFixedCommission,
};
