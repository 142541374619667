import { Box } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { Form, Formik } from 'formik';
import { ChangeEvent, Dispatch } from 'react';
import { useTranslation } from 'react-i18next';
import schemas from '../../../../config/schemas';
import { restaurantsStepsLabels } from '../../../../data/paymentSteps';
import usePaymentMethods from '../../../../hooks/queries/usePaymentMethods';
import usePaymentStepper from '../../../../hooks/usePaymentStepper';
import useWindowSize from '../../../../hooks/useWindowSize';
import { amountService } from '../../../../services/amount';
import { RootState } from '../../../../store/config';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import {
  setAmountState,
  setAmountStateError,
  setAmountStateLoading,
} from '../../../../store/slices/amount.slice';
import { setRestaurantsDebt } from '../../../../store/slices/debt.slice';
import { setStep } from '../../../../store/slices/step.slice';
import { GetPaymentAmount } from '../../../../types/amount.types';
import { Commerce } from '../../../../types/commerce.type';
import { RestaurantsDebt } from '../../../../types/debt.types';
import { PaymentStep, VerticalType, mutationKeys } from '../../../../types/enums';
import { Issuer } from '../../../../types/paymentMethod.types';
import { User } from '../../../../types/user.types';
import { getCurrencyAndTips } from '../../../../utils/restaurant.helper';
import Loading from '../../../Animations/Loading';
import CountrySelect from '../../../Common/CountrySelect';
import FormikNumberFormat from '../../../Formik/FormikNumberFormat';
import FormikSelectField from '../../../Formik/FormikSelectField';
import FormikTextField from '../../../Formik/FormikTextField';

interface DebtFormProps {
  setInvoice: Dispatch<React.SetStateAction<string>>;
  setCurrency: Dispatch<React.SetStateAction<string>>;
  setAmount: Dispatch<React.SetStateAction<number>>;
  setTipPercentage: Dispatch<React.SetStateAction<number>>;
  setCountry: Dispatch<React.SetStateAction<string | undefined>>;
  country: string | undefined;
  restaurantId?: number;
}

function DebtForm({
  setInvoice,
  setCurrency,
  setAmount,
  setTipPercentage,
  setCountry,
  country,
  restaurantId,
}: DebtFormProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { isMobile } = useWindowSize();
  const { step, updateSubmitDisabled } = usePaymentStepper();
  const user: User | null = useAppSelector((state: RootState) => state.user);
  const commerce: Commerce | null = useAppSelector((state: RootState) => state.commerce.commerce);
  const debtState: RestaurantsDebt = useAppSelector((state: RootState) => state.debt.restaurantsDebt);
  const {
    data: paymentMethods,
    isLoading,
    isError,
  } = usePaymentMethods(VerticalType.RESTAURANTS, restaurantId, country);
  const pixIssuer: Issuer | undefined = paymentMethods?.issuers[0];
  const { currencies, tipPercentages, restrictedRestaurant } = getCurrencyAndTips(restaurantId);

  const handleOnChangeTipPercentage = (
    event: ChangeEvent<HTMLInputElement>,
    setFieldValue: (field: string, value: number) => void,
  ) => {
    setFieldValue('tipPercentage', +event.target.value);
    setTipPercentage(+event.target.value);
  };

  const handleOnChangeCurrency = (
    event: ChangeEvent<HTMLInputElement>,
    setFieldValue: (field: string, value: string) => void,
  ) => {
    setFieldValue('currency', event.target.value);
    setCurrency(event.target.value);
  };

  const handleOnChangeCountry = (
    event: ChangeEvent<HTMLInputElement>,
    setFieldValue: (field: string, value: string) => void,
  ) => {
    setFieldValue('country', event.target.value);
    setCountry(event.target.value);
  };

  // TODO: add mutation key to useMutation when react & tanstack versions are updated
  const { mutateAsync } = useMutation({
    mutationFn: amountService.getPaymentAmount,
    mutationKey: mutationKeys.getPaymentAmount,
    onSuccess: (data) => {
      dispatch(setAmountState(data));
    },
    onMutate: () => {
      dispatch(setAmountStateLoading());
    },
    onError: (error: Error) => {
      dispatch(setAmountStateError(error.message));
    },
  });

  const submitRestaurantsDebt = async (values: RestaurantsDebt) => {
    dispatch(setRestaurantsDebt(values));
    if (pixIssuer && commerce?.restaurantEmployee) {
      const amountParametersRequest: GetPaymentAmount = {
        ...values,
        verticalType: VerticalType.RESTAURANTS,
        commerceId: restaurantId!,
        issuerConfigurationId: pixIssuer?.issuerConfigurationId,
        data: {
          restaurantEmployee: commerce.restaurantEmployee,
          restaurantName: commerce?.name,
          invoice: values.invoice,
          email: user?.email,
        },
      };
      await mutateAsync(amountParametersRequest);

      dispatch(setStep(PaymentStep.PAYMENT_METHODS));
    }
  };

  const initialValues: RestaurantsDebt = {
    invoice: debtState.invoice || null,
    restaurantEmployee: commerce?.restaurantEmployee || null,
    currency: 'USD',
    amount: debtState.amount || null,
    tipPercentage: debtState.tipPercentage || 0,
    country: debtState.country,
  };

  return (
    <Box className="details charge-detail">
      {isMobile && isLoading ? (
        <Loading centered />
      ) : isMobile && isError ? (
        t('errors.loading-payment-method')
      ) : isMobile && paymentMethods?.issuers.length === 0 ? (
        t('errors.no-payment-methods')
      ) : (
        <>
          <h6>{t(restaurantsStepsLabels[step])}</h6>
          <Formik
            initialValues={initialValues}
            validationSchema={schemas.RestaurantDebtSchema}
            onSubmit={submitRestaurantsDebt}
            isInitialValid={schemas.RestaurantDebtSchema.isValidSync(initialValues)}
          >
            {({ setFieldValue, isValid }) => {
              updateSubmitDisabled(!isValid);

              return (
                <Form id="restaurants-debt-form">
                  <FormikTextField
                    fullWidth
                    name="restaurantEmployee"
                    label={t('verticals.restaurants.debt-form.labels.restaurantEmployee')}
                    type="text"
                    inputMode="text"
                    disabled
                    value={commerce?.restaurantEmployee}
                  />
                  <FormikTextField
                    fullWidth
                    autoComplete="off"
                    name="invoice"
                    label={t('verticals.restaurants.debt-form.labels.invoice')}
                    type="text"
                    inputMode="text"
                    onChange={(event) => {
                      setInvoice(event.target.value);
                    }}
                  />
                  <FormikSelectField
                    name="currency"
                    fullWidth
                    label={t('verticals.restaurants.debt-form.labels.currency')}
                    options={currencies}
                    placeholder={t('verticals.restaurants.debt-form.placeholders.currency')}
                    disabled
                    onChange={(event) => {
                      handleOnChangeCurrency(event, setFieldValue);
                    }}
                  />
                  <FormikNumberFormat
                    fullWidth
                    name="amount"
                    label={t('verticals.restaurants.debt-form.labels.amount')}
                    onChange={(event) => {
                      setAmount(event.target.value);
                    }}
                  />
                  <FormikSelectField
                    name="tipPercentage"
                    fullWidth
                    label={t('verticals.restaurants.debt-form.labels.tipPercentage')}
                    options={tipPercentages}
                    placeholder="0"
                    onChange={(event) => {
                      handleOnChangeTipPercentage(event, setFieldValue);
                    }}
                  />
                  <CountrySelect onChange={(event) => handleOnChangeCountry(event, setFieldValue)} />
                </Form>
              );
            }}
          </Formik>
        </>
      )}
    </Box>
  );
}

export default DebtForm;
