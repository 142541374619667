import { Box } from '@mui/material';
import { KeyboardEvent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import usePaymentMethods from '../../hooks/queries/usePaymentMethods';
import useWindowSize from '../../hooks/useWindowSize';
import { AppDispatch, RootState } from '../../store/config';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { setIssuer } from '../../store/slices/issuer.slice';
import { Commerce } from '../../types/commerce.type';
import { Issuer } from '../../types/paymentMethod.types';
import { Vertical } from '../../types/verticals.type';
import { amountHelper } from '../../utils/amount.helper';
import { issuerHelper } from '../../utils/issuer.helper';
import { verticalHelper } from '../../utils/vertical.helper';
import PaymentMethodDefaultIcon from '../Icons/PaymentMethodDefaultIcon';

function PaymentMethodsList() {
  const { t } = useTranslation();
  const { isMobile } = useWindowSize();
  const dispatch: AppDispatch = useAppDispatch();
  const globalState = useAppSelector((state: RootState) => state);
  const vertical: Vertical | null = globalState.vertical.vertical;
  const commerce: Commerce | null = globalState.commerce.commerce;
  const selectedIssuer: Issuer | null = globalState.issuer.issuer;
  const debt =
    vertical?.verticalType && verticalHelper.getVerticalDebt(vertical?.verticalType, globalState)?.debtState;
  const {
    data: paymentMethods,
    isLoading: loading,
    error: errorMessage,
  } = usePaymentMethods(vertical?.verticalType, commerce?.commerceId, debt?.country);

  const handleKeyPress = (event: KeyboardEvent, issuer: Issuer) => {
    if (event.key === 'Enter' || event.key === ' ') dispatch(setIssuer(issuer));
  };

  useEffect(() => {
    if (paymentMethods && paymentMethods.issuers.length === 1) {
      dispatch(setIssuer(paymentMethods.issuers[0]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, paymentMethods]);

  return (
    <Box className="payment-methods-list-container">
      <h6>
        {isMobile && [0, 1, undefined].includes(paymentMethods?.issuers?.length)
          ? t('payment-method-list.detail')
          : t('payment-method-list.choose-payment')}
      </h6>
      {/* TODO: Remove Loading from box to all page, after React Query implementation */}
      {errorMessage ? (
        <p>{t('errors.loading-paymet-method')}</p>
      ) : paymentMethods?.issuers.length === 0 ? (
        <p>{t('errors.no-payment-methods')}</p>
      ) : (
        <Box className="payment-methods-list">
          {paymentMethods?.issuers.map((issuer: Issuer) => (
            <Box
              key={issuer?.issuerConfigurationId}
              tabIndex={0}
              aria-label={issuer.issuerName}
              onKeyPress={(e: KeyboardEvent) => handleKeyPress(e, issuer)}
              onClick={() => dispatch(setIssuer(issuer))}
              className={`payment-method ${amountHelper.getPaymentMethodStyle(
                issuer?.gatewayIssuerId,
                selectedIssuer?.gatewayIssuerId,
                debt?.currency,
                vertical?.verticalType,
              )}`}
            >
              {issuer.issuerImage ? (
                <img src={issuer.issuerImage} alt={issuer.issuerName} />
              ) : (
                <PaymentMethodDefaultIcon />
              )}
              <Box className="data">
                {issuer.issuerName}
                <span className="commission">
                  {t('payment-method-list.commission')}:&nbsp;
                  {amountHelper.getCommissionText(
                    issuer.commission,
                    debt?.currency === 'UYU' ? issuer.fixedCommissionUyu : issuer.fixedCommissionUsd,
                    debt?.currency,
                    issuer.gatewayIssuerId,
                    vertical?.verticalType,
                  )}
                </span>
                {issuerHelper.isPixIssuer(issuer.gatewayIssuerId) && debt?.currency === 'UYU' && (
                  <span className="disabled-text">{t('errors.no-pesos-allowed')}</span>
                )}
              </Box>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
}

export default PaymentMethodsList;
